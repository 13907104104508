<template>
  <div class="section">
    <main>
      <el-form ref="change" label-position="top" :rules="rules" label-width="80px" :model="param">
        <el-form-item label="旧密码" prop="password">
          <el-input
            v-model.trim="param.password"
            type="password"
            placeholder="请输入旧密码"
            show-password
            maxlength="16"
          />
        </el-form-item>

        <el-form-item label="新密码" prop="newPassword1">
          <el-input
            v-model.trim="param.newPassword1"
            type="password"
            placeholder="请输入新密码"
            show-password
            maxlength="16"
          />
        </el-form-item>

        <el-form-item label="确认密码" prop="newPassword2">
          <el-input
            v-model.trim="param.newPassword2"
            type="password"
            placeholder="再次输入密码"
            show-password
            maxlength="16"
          />
        </el-form-item>
      </el-form>
      <!-- <p style="margin:10px">旧密码</p>
      <el-input v-model="param.password" placeholder="请输入" size="small" show-password></el-input>
      <p style="margin:10px">新密码</p>
      <el-input
        v-model="param.newPassword1"
        placeholder="请输入"
        size="small"
        show-password
      ></el-input>
      <p style="margin:10px">确认新密码</p>
      <el-input
        v-model="param.newPassword2"
        placeholder="请输入"
        size="small"
        show-password
      ></el-input> -->
      <button class="submit" @click="cheackSubmit">保存</button>
    </main>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';

export default {
  name: 'SetPassword',
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.param.newPassword1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      param: {
        password: '',
        newPassword1: '',
        newPassword2: '',
      },
      rules: {
        password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{4,16}$/,
            message: '请输入4-16位密码，包含字母、数字、!@#$%^&*_',
            trigger: 'blur',
          },
        ],
        newPassword2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    // 判断旧密码格式
    cheackPassWord() {
      const reg = /^[A-Za-z0-9]{4,16}$/;
      const res = reg.test(this.param.password);
      return res;
    },
    // 判断新密码格式
    cheackNewPassWord() {
      const reg = /^[A-Za-z0-9]{4,16}$/;
      const res = reg.test(this.param.newPassword1);
      return res;
    },
    // 判断密码格式
    cheackSubmit() {
      this.$refs.change.validate((valid) => {
        if (valid) {
          this.submit();
        }
      });
    },
    // 修改密码
    submit() {
      if (cookies.get('token')) {
        API.repassword(this.param)
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.param = {
                password: '',
                newPassword1: '',
                newPassword2: '',
              };
            } else {
              this.$message({
                message: res.message.message,
                type: 'error',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            console.log(mgs);
          });
      } else {
        window.open(`${process.env.VUE_APP_PAY}login/:SUP/`, '_self');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 300px;
  margin: 30px 20px;
  .submit {
    margin-top: 30px;
    background: #f7b515;
    color: #ffffff;
    outline: none;
    border: transparent;
    text-align: center;
    line-height: 35px;
    width: 100px;
    height: 35px;
    border-radius: 3px;
    cursor: pointer;
  }
}
</style>
